import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Tech from "../components/Tech";
import ContactForm from "../components/ContactForm";
import Container from "../components/ui/Container";
import AttributesBox from "../components/AttributesBox";
import SystemBox from "../components/SystemBox";

function MobileAppDesign() {
  return (
    <Layout>
      <Seo
        title="BITDECODERS | MOBILE APP DESIGN & DEVELOPMENT COMPANY INDIA"
        meta={[
          {
            name: "keywords",
            content:
              "top 10 mobile app development companies, best mobile app developers, android mobile app development company, web and mobile app development companies, ios mobile app development company, mobile app design and development, mobile app development agencies, ecommerce mobile app development company, native mobile app development company, mobile application designer, mobile app design and development company, ecommerce app developers, mobile app and web development company, mobile app development companies near me, leading mobile app development company, iphone mobile app development company, mobile app designing company, app designer company, mobile app developers company, ecommerce app development companies",
          },
          {
            name: "description",
            content:
              "We specialize in providing mobile app and website designing and development services including solution integration and offshore IT outsourcing to small and medium-sized businesses (SMB) across the globe. Go with us and you can benefit from our experience, with an amazing range of skills, deep vertical industries expertise, and excellence in top technologies, like Html, Html5, Node JS, React JS, Next JS, Gatsby, Swift, Kotlin, Flutter, Data Base Management, and various other custom application fields.",
          },
        ]}
      />

      <Box position="relative">
        <StaticImage
          layout="fullWidth"
          src="../images/third-party-header.png"
          alt="Bitdecoders Mobileappdesign banner image"
        />

        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "3xl", lg: "5xl" }}
          fontWeight="bold"
          p={{ base: "4", md: "6", lg: "8" }}
          position="absolute"
          left="0"
          bottom={{ base: "0.5", md: "4", lg: "10" }}
        >
          <Text color="red.500">MOBILE APP</Text>
          <Text color="white">DESIGN &amp; DEVELOPMENT</Text>
          <Box fontSize={{ base: "xs", md: "sm", lg: "md" }} fontWeight="bold">
            <Text color="white">
              Let&apos;s talk about what we can build together
            </Text>
          </Box>
        </Heading>
      </Box>

      <Container fluid>
        <div className="space-y-10">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="mx-10 mt-6 text-lg space-y-6">
              <div>
                <p>
                  New age start-ups have also now grown up with applications
                  only like social media apps, dating apps more and more. We are
                  here to build various types of applications for your
                  convenience, also we have hands-on experience in Native
                  application development. We also can provide you with hybrid
                  app solutions also like react native, flutter.
                </p>
              </div>

              <div>
                <p>
                  1. Transform your business ideas into mock-up wireframe
                  designs and mobile fit designs.
                </p>
                <p>
                  2. We use latest architecture like MVC, MVVM to swiftly and
                  smoother progress.
                </p>
                <p>
                  3. Use latest technologies when it comes to databases and
                  APIs.
                </p>
                <p>
                  4. Making best hands on experience with the customer with best
                  algorithms.
                </p>
              </div>

              <div>
                <p>
                  For application development, we can build up Native and Hybrid
                  both for you. For native we use Swift/Objective-C for iOS and
                  Kotlin/ Java for Android. When it&apos;s come to a Hybrid app
                  we can build up with Flutter using Dart language and
                  React-Native/Ionic using the javascript-based framework.
                </p>
              </div>
            </div>
            <div className="relative md:w-3/4 md:-top-20   ">
              <ContactForm />
            </div>
          </div>
          <Box>
            <Heading
              as="h2"
              textAlign="center"
              fontWeight="medium"
              fontSize="4xl"
              textTransform="uppercase"
              mb="6"
            >
              <span className="text-red-500">
                {" "}
                MOBILE APP DESIGN &amp; DEVELOPMENT
              </span>{" "}
              ATTRIBUTES
            </Heading>

            <Grid
              my="4"
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(2, 1fr)",
                xl: "repeat(4, 1fr)",
              }}
              templateRows={{
                base: "repeat(2, 1fr)",
                md: "repeat(2, 1fr)",
                xl: "repeat(1, 1fr)",
              }}
              gap="6"
            >
              <GridItem>
                <AttributesBox description="Mobile Fit Designs Latest Architecture" />
              </GridItem>

              <GridItem>
                <AttributesBox description="Swiftly &amp; Smoother progress" />
              </GridItem>

              <GridItem>
                <AttributesBox description="Latest Technologies Contact Form Setup Live Chat Setup" />
              </GridItem>

              <GridItem>
                <AttributesBox description="Whatsapp Chat Setup Service &amp; Support More" />
              </GridItem>
            </Grid>
          </Box>

          <Box>
            <Heading
              as="h2"
              textAlign="center"
              fontWeight="medium"
              fontSize="4xl"
              textTransform="uppercase"
              mb="6"
            >
              <span className="text-red-500">
                {" "}
                MOBILE APP DESIGN &amp; DEVELOPMENT
              </span>{" "}
              PROCESS
            </Heading>

            <Grid
              my="6"
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                xl: "repeat(6, 1fr)",
              }}
              templateRows={{
                base: "repeat(3, 1fr)",
                md: "repeat(2, 1fr)",
                xl: "repeat(1, 1fr)",
              }}
              gap="6"
            >
              <GridItem>
                <SystemBox description="Planning &amp; Wireframing" />
              </GridItem>

              <GridItem>
                <SystemBox description="Flow Structure" />
              </GridItem>

              <GridItem>
                <SystemBox description="Designing &amp; Development" />
              </GridItem>

              <GridItem>
                <SystemBox description="Feedback" />
              </GridItem>

              <GridItem>
                <SystemBox description="Testing" />
              </GridItem>

              <GridItem>
                <SystemBox description="Delivery" />
              </GridItem>
            </Grid>
          </Box>
        </div>
        <div className="mt-8 ">
          <Tech />
        </div>
      </Container>
    </Layout>
  );
}

export default MobileAppDesign;
